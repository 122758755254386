<template>
	<ul class="page-list">
		<li
			v-for="(page, index) in survey.pages"
			:key="page.id"
			:class="content != page.id ? 'page' : 'selected-page'"
		>
			<button @click="$emit('input', page.id)">
				<span>{{ ++index }}</span>
				<img src="../../assets/icons/menu.svg" />
			</button>
		</li>
		<li class="new-page">
			<a href="javascript:void(0)" @click="newPage">
				<img src="../../assets/icons/add-circle-sharp.svg" />
			</a>
		</li>
	</ul>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'PageSelector',
	props: {
		value: Number,
		project: Object,
		survey: Object
	},
	data: function () {
		return {
			content: this.value
		};
	},
	watch: {
		value: function (val) {
			this.content = val;
		}
	},
	methods: {
		...mapActions(['addPage', 'deletePage']),
		newPage: function () {
			this.addPage({
				projectID: this.project.id,
				surveyID: this.survey.id
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.page-list {
	padding: 0;
	list-style: none;

	.page {
		height: 48px;
		margin: 5px 0;

		background: #333333 0% 0% no-repeat padding-box;
		border: 2px solid #333333;
		border-radius: 4px;
		opacity: 1;

		&:hover {
			background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
			border: 2px solid #fdc300;
			border-radius: 4px;
			opacity: 1;
		}

		button {
			width: 100%;
			height: 100%;

			position: relative;
			text-align: center;

			background: none;
			border: none;

			color: $unnamed-color-999999;
			cursor: pointer;

			&:hover {
				color: #fdc300;
			}

			span {
				@include normal-text-22;
				color: inherit;
			}

			img {
				width: 18px;
				height: 12px;

				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.selected-page {
		height: 48px;
		margin: 5px 0;

		background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
		border: 2px solid #fdc300;
		border-radius: 4px;
		opacity: 1;

		button {
			width: 100%;
			height: 100%;

			position: relative;
			text-align: center;

			background: none;
			border: none;

			color: $unnamed-color-999999;
			cursor: pointer;

			&:hover {
				color: #fdc300;
			}

			span {
				@include normal-text-22;
				color: inherit;
			}

			img {
				width: 18px;
				height: 12px;

				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.new-page {
		height: 48px;
		margin: 5px 0;

		background: #fdc300 0% 0% no-repeat padding-box;
		border-radius: 4px;
		opacity: 1;

		a {
			width: 100%;
			height: 100%;
			text-decoration: none;

			display: flex;
			align-items: center;
			justify-content: center;

			color: $unnamed-color-999999;

			img {
				width: 24px;
				height: 24px;
			}
		}
	}
}
</style>
